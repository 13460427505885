<template>
  <div :class="$style.detail">
    <div v-if="data.title" :class="$style.title">编辑 「{{ data.title }}」</div>
    <div v-if="loading" :class="$style.space">
      <inputText v-if="data.type === 'input_text'" :data="data" @change="onChange" />
      <inputTextArea v-if="data.type === 'input_text_area'" :data="data" @change="onChange" />
      <bjDate v-if="data.type === 'date'" :data="data" @change="onChange" />
      <bjTime v-if="data.type === 'time'" :data="data" @change="onChange" />
      <bjRadio v-if="data.type === 'radio'" :data="data" @change="onChange" />
      <bjCheckbox v-if="data.type === 'checkbox'" :data="data" @change="onChange" />

      <chead v-if="data.type === 'form_header'" :data="data" @change="onChange" />
    </div>
    <a-spin v-else :class="$style.loading" />
  </div>
</template>

<script>
import bjCheckbox from './setting/bjCheckbox'
import bjRadio from './setting/bjRadio.vue'
import bjDate from './setting/date.vue'
import head from './setting/head.vue'
import inputText from './setting/inputText.vue'
import inputTextArea from './setting/inputTextArea.vue'
import bjTime from './setting/time.vue'

export default {
  name: 'Detail',
  components: {
    inputText,
    inputTextArea,
    chead: head,
    bjDate,
    bjTime,
    bjRadio,
    bjCheckbox,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: true,
    }
  },
  watch: {
    data() {
      this.loading = false
      this.$forceUpdate()
      setTimeout(() => {
        this.loading = true
      }, 200)
    },
  },
  methods: {
    onChange(_data) {
      this.$emit('change', _data)
    },
  },
}
</script>

<style lang="less" module>
.detail {
  position: relative;
  width: 360px;
  height: 750px;
  overflow-y: auto;
  background: #fff;
  border: 1px solid #eee;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  .title {
    height: 48px;
    padding: 0 14px;
    color: #000;
    font-size: 14px;
    line-height: 48px;
    border-bottom: 1px solid #eee;
  }

  .space {
    padding: 14px;
  }

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    transform: translate(-50%, -50%);
  }
}
</style>
