<template>
  <div :class="$style.base">
    <div :class="$style.box">
      <p :class="$style.title">回收设置</p>
      <div class="layout">
        <div class="label">
          <span class="required">*</span>
          回收方式
        </div>
        <div class="value line-40">
          <a-radio-group v-model="data.recycle_type">
            <a-radio :value="0"> 满额回收 </a-radio>
            <a-radio :value="1"> 定时回收 </a-radio>
            <a-radio :value="2"> 定时满额 </a-radio>
          </a-radio-group>
          <p class="tip">设置表单的回收方式，表单达到设定的值后，其他用户将不能再次提交。</p>
        </div>
      </div>

      <div v-if="data.recycle_type === 1" class="layout">
        <div class="label">
          <span class="required">*</span>
          定时设置
        </div>
        <div class="value line-40 mb-20">
          <a-radio-group v-model="data.timing_type">
            <a-radio :value="0"> 永久有效 </a-radio>
            <a-radio :value="1"> 固定时间 </a-radio>
          </a-radio-group>
        </div>
      </div>

      <div v-if="(data.recycle_type === 1 && data.timing_type === 1) || data.recycle_type === 2" class="layout mb-20">
        <div class="label">
          <span class="required">*</span>
          回收时间
        </div>
        <div class="value line-40">
          <a-date-picker v-model="data.timing_time" show-time placeholder="请选择定时回收时间" />
        </div>
      </div>

      <div v-if="data.recycle_type === 0 || data.recycle_type === 2" class="layout">
        <div class="label">
          <span class="required">*</span>
          满额设置
        </div>
        <div class="value line-40 pt-4">
          <bj-input
            v-model="data.full_num"
            placeholder="请输入满额值"
            addon-before="收集反馈满"
            addon-after="份时结束收集"
          />
          <p class="tip">在临界值时多人同时在线填写提交可能导致超额回收的情况。</p>
        </div>
      </div>

      <div
        v-if="data.recycle_type === 0 || (data.recycle_type === 1 && data.timing_type === 1) || data.recycle_type === 2"
        class="layout mb-20"
      >
        <div class="label">提示文案</div>
        <div class="value line-40 pt-4">
          <bj-input
            v-model="data.tips"
            :addon-before="data.recycle_type === 0 || data.recycle_type === 2 ? '收集满时提示' : '时间结束时提示'"
            :placeholder="
              data.recycle_type === 0 || data.recycle_type === 2
                ? '请输入收集满额时的提示文案'
                : '请输入时间结束时的提示文案'
            "
          />
        </div>
      </div>

      <div v-if="data.recycle_type === 2" class="layout">
        <div class="label">提示文案</div>
        <div class="value line-40 pt-4">
          <bj-input v-model="data.timing_tips" addon-before="时间结束时提示" placeholder="请输入时间结束时的提示文案" />
        </div>
      </div>
    </div>

    <div :class="$style.box">
      <p :class="$style.title">高级设置</p>
      <div class="layout">
        <div class="label">
          <span class="required">*</span>
          表单模式
        </div>
        <div class="value line-40">
          <a-radio-group v-model="data.mode" :disabled="data.id !== ''">
            <a-radio :value="1"> 登记模式 </a-radio>
            <a-radio :value="2"> 预订模式 </a-radio>
          </a-radio-group>
          <p class="tip">
            设置表单模式，登记模式：仅登记表单信息；预订模式：支持付费预订、核销、退订等功能。模式一旦设置，不可更改。
          </p>
        </div>
      </div>

      <div v-if="data.mode === 2" class="layout">
        <div class="label">
          <span class="required">*</span>
          付费报名
        </div>
        <div class="value line-40">
          <a-radio-group v-model="data.is_pay">
            <a-radio :value="0"> 不需要付费 </a-radio>
            <a-radio :value="1"> 需要付费 </a-radio>
          </a-radio-group>
          <p class="tip">设置表单登记是否需要付费报名，如果需要，付费成功后报名才算成功。</p>
        </div>
      </div>

      <div v-if="data.mode === 2 && data.is_pay === 1" class="layout">
        <div class="label">
          <span class="required">*</span>
          报名费用
        </div>
        <div class="value line-40 pt-4">
          <bj-input v-model="data.pay_amount" placeholder="请输入金额" addon-before="报名费用为" addon-after="元" />
          <p class="tip">金额最多包含小数点后面两位。</p>
        </div>
      </div>

      <div v-if="data.mode === 2" class="layout">
        <div class="label">
          <span class="required">*</span>
          过期时间
        </div>
        <div class="value line-40">
          <a-radio-group v-model="data.is_expired">
            <a-radio :value="0"> 不需要过期 </a-radio>
            <a-radio :value="1"> 需要过期 </a-radio>
          </a-radio-group>
          <p class="tip">设置表单登记完成后，用户的登记凭证，是否存在有效期，有效期之后凭证将无效。</p>
        </div>
      </div>

      <div v-if="data.mode === 2 && data.is_expired === 1" class="layout mb-20">
        <div class="label">
          <span class="required">*</span>
          过期时间
        </div>
        <div class="value line-40">
          <a-date-picker v-model="data.expired_time" show-time placeholder="请选择过期时间" />
        </div>
      </div>

      <div v-if="data.mode === 2" class="layout">
        <div class="label">
          <span class="required">*</span>
          退订方式
        </div>
        <div class="value line-40 mb-20">
          <a-radio-group v-model="data.unsubscribe_mode">
            <a-radio :value="0"> 不可退订 </a-radio>
            <a-radio :value="1"> 随时可退 </a-radio>
            <a-radio v-if="data.is_expired === 1" :value="2"> 过期前退 </a-radio>
          </a-radio-group>
          <p class="tip">设置表单登记完成后，用户是否可以退订。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'cbase',
  props: {
    pageData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: {},
    }
  },
  computed: {},
  watch: {
    pageData: {
      handler(val) {
        this.data = val
      },
      immediate: true,
    },
  },
  methods: {},
}
</script>

<style lang="less" module>
.base {
  .title {
    margin-bottom: 20px;
    font-size: 14px;
  }

  .box {
    width: 100%;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #fff;

    :global {
      .ant-form-item {
        margin-bottom: 0;
      }

      .ant-calendar-picker {
        width: 100%;
      }
    }
  }
}
</style>

<style scoped>
.value {
  max-width: 600px;
}
</style>
