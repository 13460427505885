<template>
  <div :class="$style.content">
    <BjForm ref="form">
      <BjInput
        v-model="param.label"
        v-bind="layout"
        rules="required"
        tooltips
        label-align="left"
        placeholder="请输入表单标题"
        label="表单标题"
      />
      <BjInput
        v-model="param.help"
        class="mt-20"
        v-bind="layout"
        label-align="left"
        placeholder="请输入表单的帮助信息"
        label="帮助信息"
      />
      <BjValidationItem class="mt-20" label-align="left" rules="required" label="选项内容" v-bind="layout" />
      <div :class="$style.box">
        <div v-for="(item, index) in param.option" :key="index">
          <BjInput v-model="item.label">
            <template #addonAfter>
              <div :class="$style.addon">
                <i
                  :class="allowUp(index) ? $style.edit : $style.disable"
                  class="ri-arrow-up-line"
                  @click="onMoveUp(index)"
                />
                <i
                  :class="allowDown(index) ? $style.edit : $style.disable"
                  class="ri-arrow-down-line"
                  @click="onMoveDown(index)"
                />
                <i :class="$style.delete" class="ri-close-circle-line" @click="onDelete(index)" />
              </div>
            </template>
          </BjInput>
        </div>
        <div :class="$style.line" />
        <BjButton block @click="onAdd()">
          <i class="ri-add-line left" />
          添加选项
        </BjButton>
      </div>
      <BjValidationItem class="mt-20" label-align="left" rules="required" label="是否必填" v-bind="layout">
        <BjSwitch v-model="param.required" />
      </BjValidationItem>
    </BjForm>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'inputText',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
    }
  },
  watch: {
    data: {
      handler(val) {
        this.param = val
      },
      immediate: true,
    },
  },
  methods: {
    onAdd() {
      this.param.option.push({
        label: null,
      })
      // this.onChange()
    },
    allowUp(index) {
      return index !== 0
    },
    allowDown(index) {
      return index !== this.param.option.length - 1
    },
    swap(array, first, second) {
      let tmp = array[second]
      array[second] = array[first]
      array[first] = tmp
      return array
    },
    onMoveUp(index) {
      if (!this.allowUp(index)) {
        return
      }
      this.param.option = _.cloneDeep(this.swap(this.param.option, index, index - 1))
    },
    onMoveDown(index) {
      if (!this.allowDown(index)) {
        return
      }
      this.param.option = _.cloneDeep(this.swap(this.param.option, index, index + 1))
    },
    onDelete(index) {
      this.param.option.splice(index, 1)
    },
    // onChange() {
    //   this.$emit('change', this.param)
    // },
  },
}
</script>

<style lang="less" module>
.content {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .box {
    padding: 10px;
    background: #fafafa;
  }

  .title {
    color: #000;
    font-size: 14px;
  }

  .line {
    width: 100%;
    border-top: 1px solid #eee;
    margin: 10px 0;
  }
}

.addon {
  i {
    font-size: 16px;
  }

  .delete {
    color: @error-color;
  }

  .edit {
    color: @primary-color;
    margin-right: 4px;
  }

  .disable {
    color: #bbb !important;
    margin-right: 4px;
  }
}
</style>
