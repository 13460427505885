<template>
  <div :class="$style.left">
    <p :class="$style.title">表单组件</p>
    <draggable
      v-model="data"
      animation="300"
      :move="onMove"
      :group="{ name: 'site', pull: 'clone', put: true }"
      :clone="clone"
    >
      <transition-group>
        <div v-for="item in data" :key="item.type" :class="$style.item" @click="addItem(item)">
          <i :class="item.icon" />
          <span> {{ item.title }}</span>
        </div>
      </transition-group>
    </draggable>
  </div>
</template>

<script>
import _ from 'lodash'
import draggable from 'vuedraggable'

import { guid } from '@/utils'

export default {
  name: 'left',
  components: {
    draggable,
  },
  data() {
    return {
      data: [
        //  单行文本
        {
          title: '单行文本',
          type: 'input_text', // 组件类型
          label: '', // 表单标题
          required: 1, // 是否必选 0-否 1-是
          help: '', // 帮助信息
          placeholder: '', // 提示信息
          validate_type: 'default',
          icon: 'ri-subtract-line',
          validate_type_data: [
            { name: '无验证格式', id: 'default' },
            { name: '数字', id: 'number' },
            { name: '邮箱', id: 'email' },
            { name: '电话号码', id: 'phone' },
            { name: '手机号', id: 'telephone' },
            { name: '身份证号', id: 'id_card' },
          ],
        },
        // 多行文本
        {
          title: '多行文本',
          type: 'input_text_area', // 组件类型
          label: '', // 表单标题
          required: 1, // 是否必选 0-否 1-是
          help: '', // 帮助信息
          placeholder: '', // 提示信息
          validate_type: 'default',
          icon: 'ri-bill-line',
          validate_type_data: [
            { name: '无验证格式', id: 'default' },
            { name: '数字', id: 'number' },
            { name: '邮箱', id: 'email' },
            { name: '电话号码', id: 'phone' },
            { name: '手机号', id: 'telephone' },
            { name: '身份证号', id: 'id_card' },
          ],
        },
        // 单选选择
        {
          title: '单项选择',
          type: 'radio', // 组件类型
          label: '', // 表单标题
          required: 1, // 是否必选 0-否 1-是
          help: '', // 帮助信息
          option: [],
          icon: 'ri-checkbox-circle-line',
        },
        // 多选选择
        {
          title: '多项选择',
          type: 'checkbox', // 组件类型
          label: '', // 表单标题
          required: 1, // 是否必选 0-否 1-是
          help: '', // 帮助信息
          option: [], // {label: ''}
          icon: 'ri-checkbox-circle-line',
        },
        // 日期
        {
          title: '日期选择',
          type: 'date', // 组件类型
          label: '', // 表单标题
          required: 1, // 是否必选 0-否 1-是
          help: '', // 帮助信息
          format: 'ymd', // 日期格式 ymd-年月日  ym-年月 range-日期范围
          icon: 'ri-calendar-line',
        },
        // 时间
        {
          title: '时间选择',
          type: 'time', // 组件类型
          label: '', // 表单标题
          required: 1, // 是否必选 0-否 1-是
          help: '', // 帮助信息
          icon: 'ri-time-line',
        },
      ],
    }
  },
  methods: {
    onMove() {
      return true
    },
    clone(item) {
      let tmp = { ..._.cloneDeep(item), _id: guid() }
      this.$emit('active', tmp)
      return tmp
    },
    addItem(item) {
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('addItem', { ..._.cloneDeep(item), _id: guid() })
    },
  },
}
</script>

<style lang="less" module>
.left {
  width: 280px;
  height: 747px;
  padding: 20px 14px;
  background: #fff;
  border: 1px solid #eee;

  .title {
    margin-bottom: 14px;
    color: #000;
    font-size: 14px;
  }

  .box {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .item {
    display: inline-block;
    width: 120px;
    height: 42px;
    line-height: 42px;
    border: 1px solid #eee;
    cursor: pointer;
    margin-bottom: 10px;

    i {
      margin: 0 8px 0 12px;
      color: #000 !important;
      font-size: 18px;
      vertical-align: -4px;
    }
  }

  .item:nth-child(Odd) {
    margin-right: 10px;
  }
}
</style>
