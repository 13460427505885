<template>
  <div :class="$style.content">
    <BjForm ref="form">
      <BjInput
        v-model="param.label"
        v-bind="layout"
        rules="required"
        tooltips
        label-align="left"
        placeholder="请输入表单标题"
        label="表单标题"
      />
      <BjInput
        v-model="param.help"
        class="mt-20"
        v-bind="layout"
        label-align="left"
        placeholder="请输入表单的帮助信息"
        label="帮助信息"
      />
      <BjInput
        v-model="param.placeholder"
        class="mt-20"
        v-bind="layout"
        label-align="left"
        placeholder="请输入表单的提示信息"
        label="提示信息"
      />
      <BjSelect
        v-model="param.validate_type"
        class="mt-20"
        rules="required"
        label-align="left"
        v-bind="layout"
        key-field="id"
        label="验证格式"
        inner-search
        placeholder="请选择表单的验证格式"
        value-field="id"
        label-field="name"
        show-all
        :options="typeData"
      />
      <BjValidationItem class="mt-20" label-align="left" rules="required" label="是否必填" v-bind="layout">
        <BjSwitch v-model="param.required" />
      </BjValidationItem>
    </BjForm>
  </div>
</template>

<script>
export default {
  name: 'inputText',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      typeData: [
        {
          name: '无',
          id: 'default',
        },
        {
          name: '数字',
          id: 'number',
        },
        {
          name: '邮箱',
          id: 'email',
        },
        {
          name: '电话号码',
          id: 'phone',
        },
        {
          name: '手机号码',
          id: 'telephone',
        },
        {
          name: '身份证号码',
          id: 'id_card',
        },
      ],
    }
  },
  watch: {
    data: {
      handler(val) {
        this.param = val
      },
      immediate: true,
    },
  },
  methods: {
    // onChange() {
    //   this.$emit('change', this.param)
    // },
  },
}
</script>

<style lang="less" module>
.content {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .box {
    padding: 10px;
    background: #fafafa;

    .line {
      width: 100%;
      margin: 10px 0;
      border-top: 1px solid #eee;
    }
  }

  .title {
    color: #000;
    font-size: 14px;
  }

  .box-add {
    width: 100%;
    height: 80px;
    color: #000;
    font-size: 12px;
    line-height: 80px;
    text-align: center;
    background: #fff;
    border: 1px solid #eee;
    cursor: pointer;

    i {
      color: #000;
      font-size: 18px;
      vertical-align: -4px;
    }
  }

  .box-edit {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
    margin-top: 10px;
    padding: 5px;
    color: #000;
    background: #fff;
    border: 1px solid #eee;

    .img {
      position: relative;
      width: 70px;
      height: 70px;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 100%;
        max-height: 100%;
        transform: translate(-50%, -50%);
      }
    }

    .text {
      flex: 1;
      padding: 10px;
    }

    .btn {
      width: 25px;

      i {
        margin-right: 5px;
        font-size: 18px;
      }
    }
  }
}

.disable {
  color: #bbb !important;
  cursor: not-allowed;
}
</style>
