<template>
  <div :class="$style.content">
    <BjForm ref="form">
      <BjInput
        v-model="param.name"
        v-bind="layout"
        rules="required"
        tooltips
        label-align="left"
        placeholder="请输入表单标题"
        label="表单名称"
      />
      <BjValidationItem class="mt-20" label-align="left" label="描述内容" v-bind="layout">
        <a-textarea v-model="param.summary" placeholder="请输入表单的描述信息。" :rows="3" />
      </BjValidationItem>
      <BjValidationItem class="mt-20" label-align="left" label="背景素材" v-bind="layout">
        <selectMaterial :data="param.background" :allow="['image']" @change="coverChange" />
        <div class="tip mt-20">背景素材支持图片，图片大小建议不超过2M。</div>
      </BjValidationItem>
    </BjForm>
  </div>
</template>

<script>
import selectMaterial from '@/components/selectMaterial'

export default {
  name: 'inputTextArea',
  components: {
    selectMaterial,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
    }
  },
  watch: {
    data: {
      handler(val) {
        this.param = val
      },
      immediate: true,
    },
  },
  methods: {
    onChange() {
      this.$emit('change', this.param)
    },
    coverChange(data) {
      this.param.background = data[0]
      this.onChange()
    },
  },
}
</script>

<style lang="less" module>
.content {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
</style>
