<template>
  <div>
    <div v-if="loading" :class="$style.loading">
      <a-spin />
    </div>
    <div v-show="step === 0" :class="$style.add">
      <Left @active="active" @addItem="addItem" />
      <Content ref="content" :page-data="pageData" @change="change" />
      <Right :data="detailData" @change="change" />
    </div>
    <div v-show="step === 1">
      <cBase ref="cbase" :page-data="pageData" @refresh="getInfo" />
    </div>
    <div v-show="step === 2">
      <State :page-data="pageData" @refresh="getInfo" />
    </div>
    <div :class="$style.footer" :style="{ width: footWidth, left: left }">
      <div v-if="step === 0" :class="$style.btns">
        <BjButton type="primary" @click="initSubmit()"><i class="ri-arrow-right-line left" />下一步</BjButton>
      </div>
      <div v-if="step === 1" :class="$style.btns">
        <BjButton class="btn-default" @click="back(0)"><i class="ri-arrow-left-line left" />上一步</BjButton>
        <BjButton type="primary" @click="baseSubmit()"><i class="ri-arrow-right-line left" />下一步</BjButton>
      </div>
      <div v-if="step === 2" :class="$style.btns">
        <BjButton class="btn-default" @click="back(1)"><i class="ri-arrow-left-line left" />上一步</BjButton>
        <BjButton type="primary" @click="backHome()"><i class="ri-arrow-go-back-line left" />返回列表</BjButton>
      </div>
      <div :class="$style.steps">
        <a-steps :class="$style.step" size="small" :current="step">
          <a-step title="表单内容" />
          <a-step title="表单设置" />
          <a-step title="完成发布" />
        </a-steps>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'

import { appFormServce } from '@/service'
import { guid } from '@/utils'

import cbase from './cbase.vue'
import content from './content'
import left from './left'
import right from './right'
import state from './state.vue'

const service = new appFormServce()

export default {
  name: 'Home',
  components: {
    Left: left,
    Content: content,
    Right: right,
    cBase: cbase,
    State: state,
  },
  data() {
    return {
      pageData: {},
      detailData: {},
      step: 0,
      loading: false,
      id: '',
    }
  },
  computed: {
    collapsed: {
      get() {
        return this.$store.state.collapsed
      },
      set(val) {
        this.$store.commit('COLLAPSED', val)
      },
    },
    sideChirdShow: {
      get() {
        return this.$store.state.sideChirdShow
      },
      set(val) {
        this.$store.commit('SIDECHIRDSHOW', val)
      },
    },
    footWidth() {
      let left = this.collapsed ? (this.sideChirdShow ? '260px' : '100px') : this.sideChirdShow ? '340px' : '180px'
      let w = 'calc(100% - ' + left + ')'
      return w
    },
    left() {
      let left = this.collapsed ? (this.sideChirdShow ? '240px' : '80px') : this.sideChirdShow ? '320px' : '160px'
      return left
    },
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id
    } else {
      this.id = ''
    }
    this.getInfo()
  },
  methods: {
    async getInfo() {
      try {
        this.loading = true
        const { data } = await service.getInfo({
          id: this.id,
        })
        this.pageData = data
        this.pageData.component.map(item => {
          item._id = guid()
        })
        this.pageData = _.cloneDeep(this.pageData)
        this.loading = false
      } catch (e) {}
    },
    active(data) {
      this.$refs.content.activeId(data)
    },
    change(_data) {
      this.detailData = _data
    },
    addItem(item) {
      this.$refs.content.activeId(item)
      this.$refs.content.data.push(item)
      this.$refs.content.add()
    },
    initSubmit() {
      if (this.$refs.content.data.length < 2) {
        this.$message.error('请添加表单组件后进行下一步')
        return
      }
      this.step = 1
    },
    back(step) {
      this.step = step
    },
    async baseSubmit() {
      let expiredTime = ''
      let timing_time = ''
      if (
        this.$refs.cbase.data.mode === 2 &&
        this.$refs.cbase.data.is_expired === 1 &&
        this.$refs.cbase.data.expired_time
      ) {
        expiredTime = moment.isMoment(this.$refs.cbase.data.expired_time)
          ? this.$refs.cbase.data.expired_time.format('YYYY-MM-DD HH:mm:ss')
          : this.$refs.cbase.data.expired_time
      }

      if (
        (this.$refs.cbase.data.recycle_type === 1 && this.$refs.cbase.data.timing_type === 1) ||
        this.$refs.cbase.data.recycle_type === 2
      ) {
        timing_time = moment.isMoment(this.$refs.cbase.data.timing_time)
          ? this.$refs.cbase.data.timing_time.format('YYYY-MM-DD HH:mm:ss')
          : this.$refs.cbase.data.timing_time
      }

      const { data } = await service.save({
        ...this.pageData,
        ...this.$refs.cbase.data,
        component: this.$refs.content.data,
        expired_time: expiredTime,
        timing_time: timing_time,
      })
      if (!this.id) {
        this.id = data
        this.$router.push({
          name: 'appFormAdd',
          query: {
            id: data,
          },
        })
      }
      this.getInfo()
      this.step = 2
    },
    backHome() {
      this.$router.push({
        name: 'appFormManage',
      })
    },
  },
}
</script>

<style lang="less" module>
.add {
  display: flex;
}

.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.footer {
  position: fixed;
  bottom: 0;
  left: 15px;
  z-index: 99;
  display: flex;
  align-items: center;
  height: 60px;
  padding: 10px;
  background: #fff;
  box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, 0.05);

  .btns {
    width: 280px;

    button {
      margin: 0 10px;
    }

    i {
      font-size: 18px;
      vertical-align: -4px;
    }
  }

  .steps {
    flex: 1;

    .step {
      width: 500px;
      margin-left: auto;
    }

    :global {
      .ant-steps-item-title {
        font-size: 14px;
      }
    }
  }
}
</style>
