<template>
  <div :class="$style.content">
    <div :class="$style.box">
      <img key="phoneHead" :class="$style.phoneHead" :src="oss + '/manage/media/assets/statusbar_b.png'" />
      <draggable
        v-model="data"
        :class="$style.container"
        group="site"
        animation="300"
        :move="onMove"
        chosen-class="ghostchosen"
        ghost-class="ghostclass"
        filter=".forbid"
        @add="add"
      >
        <div class="forbid ellipsis" :class="$style.templateTitle">
          {{ data ? data[0].name || '表单名称' : null }}
        </div>
        <transition-group>
          <div
            v-for="(item, index) in data"
            :key="item._id"
            :class="[$style.ghost, id === item._id ? 'ghost-active' : null, 'forbid']"
          >
            <div v-if="item.type === 'form_header'" class="forbid" :class="$style.templateHead" @click="change(item)">
              <img :class="$style.templateImg" :src="item.background.path" />
              <div :class="$style.templateName" class="ellipsis">{{ item.name || '表单名称' }}</div>
              <div :class="$style.templateSummary" class="ellipsis-width2">
                {{ item.summary || '这里显示表单的描述内容，最多显示两排。' }}
              </div>
            </div>
            <input-text v-if="item.type === 'input_text'" :data="item" @change="change" />
            <inputTextArea v-if="item.type === 'input_text_area'" :data="item" @change="change" />
            <bjDate v-if="item.type === 'date'" :data="item" @change="change" />
            <bjTime v-if="item.type === 'time'" :data="item" @change="change" />
            <bjRadio v-if="item.type === 'radio'" :data="item" @change="change" />
            <bjCheckbox v-if="item.type === 'checkbox'" :data="item" @change="change" />
            <div class="editoractive" />
            <div v-if="id === item._id && item.type !== 'form_header'" :class="$style.sideBtn">
              <div v-if="index !== 0 && index !== 1" :class="$style.btn" @click="onMoveUp(index)">
                <i class="ri-arrow-up-line" />
              </div>
              <div v-if="index !== data.length - 1" :class="$style.btn" @click="onMoveDown(index)">
                <!-- 下 -->
                <i class="ri-arrow-down-line" />
              </div>
              <div :class="$style.btn" @click="onCopy(index)">
                <!-- 复制 -->
                <i class="ri-file-copy-line" />
              </div>
              <div :class="$style.btn" @click="onDelete(index)">
                <!-- 删除 -->
                <i class="ri-delete-bin-7-line" />
              </div>
            </div>
          </div>
        </transition-group>
      </draggable>
      <img key="phoneFoot" :class="$style.phoneFoot" :src="oss + '/manage/media/assets/homebar.png'" alt="" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import draggable from 'vuedraggable'

import { guid } from '@/utils'

import bjCheckbox from './components/bjCheckbox'
import bjRadio from './components/bjRadio.vue'
import bjDate from './components/date.vue'
import inputText from './components/inputText.vue'
import inputTextArea from './components/inputTextArea.vue'
import bjTime from './components/time.vue'

export default {
  name: 'Content',
  components: {
    draggable,
    inputText,
    inputTextArea,
    bjDate,
    bjTime,
    bjRadio,
    bjCheckbox,
  },
  props: {
    pageData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: [],
      id: null,
    }
  },
  watch: {
    pageData: {
      handler(val) {
        this.data = val.component
        if (this.data && this.data.length) {
          this.change(this.data[0])
        }
      },
      immediate: true,
    },
    data: {
      handler() {
        // eslint-disable-next-line vue/custom-event-name-casing
        this.$emit('refreshList')
      },
      immediate: true,
    },
  },
  methods: {
    onMoveUp(index) {
      this.data = _.cloneDeep(this.swap(this.data, index, index - 1))
      this.change(this.data[index - 1])
    },
    onMoveDown(index) {
      this.data = _.cloneDeep(this.swap(this.data, index, index + 1))
      this.change(this.data[index + 1])
    },
    onDelete(index) {
      this.data.splice(index, 1)
      this.data = _.cloneDeep(this.data)
      if (index !== 0) {
        // 删除了选择上一个
        this.id = this.data[index - 1]._id
        this.change(this.data[index - 1])
      }
      // eslint-disable-next-line vue/custom-event-name-casing
      this.$emit('refreshList')
    },
    onCopy(index) {
      // 复制修改id
      this.data.push(_.cloneDeep({ ...this.data[index], _id: guid() }))
    },
    swap(array, first, second) {
      let tmp = array[second]
      array[second] = array[first]
      array[first] = tmp
      return array
    },
    change(_data) {
      this.id = _data._id
      this.$emit('change', _data)
    },
    onMove(e, x) {
      if (x.clientX < 250) return false // 右往左
      return true
    },
    add() {
      //   let tmp = []
      //   let last = null
      //   this.data.map(item => {
      //     if (item.position === 'first') {
      //       tmp.unshift(item)
      //     } else if (item.position === 'last') {
      //       last = item
      //     } else {
      //       tmp.push(item)
      //     }
      //   })
      //   if (last) {
      //     tmp.push(last)
      //   }
      //   this.data = _.cloneDeep(tmp)
    },
    // eslint-disable-next-line vue/no-unused-properties
    activeId(data) {
      setTimeout(() => {
        this.data.map(item => {
          if (item._id === data._id) {
            this.change(item)
          }
        })
      }, 500)
    },
  },
}
</script>

<style lang="less" module>
.content {
  position: relative;
  flex: 1;
  box-sizing: content-box;
  height: calc(100vh - 202px);
  padding-top: 1px;
  overflow-y: auto;
  background-color: #fafafa;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none; /* Chrome Safari */
  }

  .box {
    position: relative;
    width: 390px;
    min-height: 750px;
    margin: 0 auto 20px;
  }

  .container {
    position: relative;
    width: 390px;
    min-height: 750px;
    padding-top: 44px;
    padding-bottom: 34px;
    font-size: 14px;
    background-color: #fff;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);

    & > span {
      display: block;
      width: 100%;
      min-height: calc(100vh - 295px);
    }
  }
}

.ghost {
  position: relative;
  margin-bottom: 20px;
  cursor: grab;
}

.template-title {
  height: 44px;
  color: #000;
  font-size: 16px;
  line-height: 44px;
  text-align: center;
  background: #fff;
  padding: 0 20px;
}

.template-head {
  padding: 0 20px;
  width: 100%;
  height: 200px;
  position: relative;
}

.template-name {
  width: 100%;
  position: absolute;
  padding: 0 20px;
  top: 72px;
  left: 0;
  text-align: center;
  font-size: 20px;
  color: #fff;
}

.template-summary {
  width: 100%;
  position: absolute;
  padding: 0 20px;
  top: 110px;
  left: 0;
  text-align: center;
  font-size: 13px;
  color: #fff;
}

.template-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.back {
  background: #fff;
}

.phone-head {
  position: absolute;
  top: 0;
  z-index: 666;
  width: 390px;
  user-select: none;
}

.phone-foot {
  position: absolute;
  bottom: 20px;
  z-index: 666;
  width: 390px;
  user-select: none;
}

.side-btn {
  position: absolute;
  top: 0;
  right: -50px;
  width: 40px;
  font-size: 18px;
  background: #fff;
  border: 1px solid #eee;

  .btn {
    height: 40px;
    color: #000;
    line-height: 40px;
    text-align: center;
    border-bottom: 1px solid #eee;
    cursor: pointer;
  }

  .btn:last-child {
    border-bottom: none;
  }

  .btn:hover {
    color: @primary-color;
    background: #eee;
  }
}
</style>

<style lang="less">
.editoractive {
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  display: none;
  border: 2px solid @primary-color;
  pointer-events: none;
}

.ghostchosen {
  .editoractive {
    display: block;
    backdrop-filter: saturate(180%) blur(6px);
  }
}

.ghostclass {
  cursor: grabbing;
}

.ghostchosen:hover {
  cursor: grabbing;
}

.ghost-active {
  .editoractive {
    display: block;
  }
}

.cbox {
  width: 100%;
  height: 70px;
  color: @primary-color;
  font-size: 12px;
  text-align: center;
  border: 1px solid @primary-color;
  cursor: pointer;
  /* stylelint-disable-next-line no-descending-specificity */
  i {
    color: @primary-color;
    font-size: 24px;
  }
}
</style>
