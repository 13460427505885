<template>
  <div :class="$style.relesase">
    <div :class="$style.box">
      <div :class="$style.cover">
        <img :class="$style.icon" :src="oss + '/manage/icon/default/done.png'" />
      </div>
      <p :class="$style.title">表单编辑完成</p>
      <p :class="$style.tip">表单已创建成功，请确认无误后再进行发布！</p>
      <div :class="$style.btn">
        <BjButton class="mr-20" @click="onPreview()">
          <i class="ri-eye-line left" />
          预览表单
        </BjButton>
        <BjButton v-if="pageData.status === 2" type="primary" @click="sumbit()">
          <i class="ri-send-plane-2-line left" />
          立即发布
        </BjButton>
        <BjButton v-else @click="cancel()">
          <i class="ri-arrow-down-line left" />
          取消发布
        </BjButton>
      </div>
    </div>
  </div>
</template>

<script>
import { appFormServce } from '@/service'

const service = new appFormServce()

export default {
  name: 'state',
  props: {
    pageData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async sumbit() {
      await service.status({
        id: this.pageData.id,
        status: this.pageData.status === 1 ? 2 : 1,
      })
      this.$message.success(this.pageData.status === 1 ? '取消成功' : '发布成功')
      this.$emit('refresh')
    },
    onPreview() {
      this.$store.commit('EDITORBACKNAME', this.$route.fullPath)
      this.$router.push({
        name: 'frontPreview',
        query: {
          type: 'app_form',
          id: this.pageData.id,
        },
      })
    },
    async cancel() {
      await service.status({
        id: this.pageData.id,
        status: this.pageData.status === 1 ? 2 : 1,
      })
      this.$message.success(this.pageData.status === 1 ? '取消成功' : '发布成功')
      this.$emit('refresh')
    },
  },
}
</script>

<style lang="less" module>
.relesase {
  position: relative;
  width: 100%;
  height: 600px;

  .title {
    margin-bottom: 10px;
    font-size: 20px;
  }

  .tip {
    margin-bottom: 30px;
    color: #5c5c5c;
    font-size: 12px;
  }

  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    height: 300px;
    text-align: center;
    transform: translate(-50%, -50%);
  }

  .cover {
    position: relative;
    height: 120px;

    img {
      width: 150px;
      height: 150px;
      border-radius: 6px;
      object-fit: cover;
    }

    .icon {
      position: absolute;
      top: 50px;
      left: 125px;
      width: 50px;
      height: 50px;
    }
  }
}
</style>
