<template>
  <div :class="$style.input" @click="onChoose()">
    <p :class="$style.text">
      <span>{{ data.label || '多行文本' }}</span>
      <span v-if="data.required" :class="$style.tag">必填</span>
    </p>
    <a-textarea :placeholder="placeholder" />
    <p v-if="data.help" class="tip mt-10">{{ data.help }}</p>
  </div>
</template>

<script>
export default {
  name: 'inputTextArea',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {}
  },
  computed: {
    placeholder() {
      return this.data.placeholder || '这里是默认提示文字'
    },
  },
  methods: {
    onChoose() {
      this.$emit('change', this.data)
    },
  },
}
</script>

<style lang="less" module>
.input {
  padding: 0 20px 10px;

  :global {
    .ant-form-item {
      margin-bottom: 10px;
    }
  }

  .text {
    position: relative;
    margin-bottom: 10px;
    font-size: 14px;
  }

  .tag {
    position: absolute;
    top: 1px;
    right: 0;
    display: inline-block;
    width: 40px;
    height: 20px;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    background-color: @primary-color;
    border-radius: 0 6px 0 6px;
  }
}
</style>
